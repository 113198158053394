import React, { useContext } from 'react'
import Buttons from './Buttons'
import './Button_cnt.css'
import { Link } from 'react-router-dom'
import { E_invoice_context } from '../../E_invoice_context'



const Button_container = () => {

    const { setunitshow, setinvoicetypeshow, setInvoiceDataMaster, setstartdate, setenddate, pendingcredithandlePageChange, handleNON_CMSCustomerPaginationNumber, setSearch, setEmailinvoicesearch, creditinvoicerecord,
        setitemshow, setUserPages, SetShow, Modules, setModules, handleAscendingClick, setCreditShow, setapprovalcreditshow, credithandlePageChange, StatehandlePageChange, setcreditinvoicerecord,
        setpendingCreditShow, setstateconfig, approvalcredithandlePageChange, setcreditsearchvalue, setapprovalcreditinvoicerecord, setGetRecords, GetRecords, setinvoicetypesearch, setunitsearch,
        setapprovalcreditsearch, approvalcreditinvoicerecord, setpendingcreditsearch, setnonCMS, nonCMS, setnoncmssearch, setinvoicedropdownvalue, setinvoicetypeenbled, setinvoicetypeenbledvalue,
        UserhandlePageChange, invoiceTypehandlePageChange, unithandlePageChange, setitemdropdownvalue, setitemenabledvalue, setitemenabled, setitemsearch, setstatesearch,
        itemhandlePageChange, setpendingsetcreditinvoicerecord, pendingcreditinvoicerecord } = useContext(E_invoice_context)

    const handleClearAndClick = () => {
        SetShow(1);
        setSearch('')
        setEmailinvoicesearch('')
    }

    const state_code_config = () => {
        setstatesearch('')
        setstateconfig(1)
        StatehandlePageChange(1);
    }
    const item_master_intital = () => {
        setitemsearch('')
        setitemenabled(null)
        setitemenabledvalue('')
        setitemdropdownvalue('')
        handleAscendingClick();
        setitemshow(1);
        itemhandlePageChange(1);
    }
    const unit_master_intitial = () => {
        setunitsearch('')
        handleAscendingClick();
        setunitshow(1);
        unithandlePageChange(1);
    }
    const invoice_type_intial = () => {
        setinvoicetypeenbledvalue('')
        setinvoicetypesearch('')
        setinvoicetypeenbled(null);
        setinvoicedropdownvalue('')
        handleAscendingClick();
        setinvoicetypeshow(1)
        invoiceTypehandlePageChange(1);
    }
    const credit_note_tab = () => {
        setcreditinvoicerecord({ ...creditinvoicerecord, invoiceTypeIdentifier: creditinvoicerecord.invoiceTypeIdentifier = "" })
        setcreditinvoicerecord({ ...creditinvoicerecord, supplierIdentifier: creditinvoicerecord.supplierIdentifier = "" })
        setcreditinvoicerecord({ ...creditinvoicerecord, statusFlag: creditinvoicerecord.statusFlag = "3" })
        setcreditinvoicerecord({ ...creditinvoicerecord, search: creditinvoicerecord.search = "" })
        // getcreditInvoicedata();
        setcreditsearchvalue('')
        credithandlePageChange(1);
        setCreditShow(1);
    }
    const credit_pending_tab = () => {
        setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, invoiceTypeIdentifier: pendingcreditinvoicerecord.invoiceTypeIdentifier = "" })
        setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, supplierIdentifier: pendingcreditinvoicerecord.supplierIdentifier = "" })
        setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, search: pendingcreditinvoicerecord.search = "" })
        setpendingcreditsearch('')
        setpendingCreditShow(1)
        pendingcredithandlePageChange(1)
    }
    const custom_master = () => {
        setnonCMS({ ...nonCMS, billingStatesCode: nonCMS.billingStatesCode = "", shippingStatesCode: nonCMS.shippingStatesCode = "", search: nonCMS.search = "", regUnRegFlag: nonCMS.regUnRegFlag = "0" })
        setnoncmssearch('')
        handleNON_CMSCustomerPaginationNumber(1)
    }
    const app_credit_note_tab = () => {
        setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, invoiceTypeIdentifier: approvalcreditinvoicerecord.invoiceTypeIdentifier = "" })
        setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, supplierIdentifier: approvalcreditinvoicerecord.supplierIdentifier = "" })
        setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, search: approvalcreditinvoicerecord.search = "" })
        setapprovalcreditsearch('')
        approvalcredithandlePageChange(1)
        setapprovalcreditshow(1)
    }
    const usermaster = () => {
        setGetRecords({ ...GetRecords, search: GetRecords.search = "" })
        setUserPages(1)
        UserhandlePageChange(1)
    }
    return (
        <div className='button_cnt_in'>
            {(Modules && Modules.includes(6) || Modules && Modules.includes(0)) && <><div className='head_top_of_b_c_n_t'>
                <p>Invoice </p>
            </div>
                <div className='row'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/All_invoice" onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"All Invoice"}
                                innerclr={"7a5029"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Pending_approvel" onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Pending Approval"}
                                innerclr={"b3234b"}
                            />
                        </Link>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Invoice_approved" onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Invoices Approved"}
                                innerclr={"2a6d26"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12  pe-1 btn_in_prp'>
                        <Link to="/invoice_rejected" onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Invoices Rejected"}
                                innerclr={"757474"}
                            />
                        </Link>
                    </div>

                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/invoice_email_ls" onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Invoices Email List"}
                                innerclr={"03a2d6"}
                            />
                        </Link>
                    </div>
                    {/* <div className='col-lg-6 col-12  pe-1 btn_in_prp'>
                        <Link to="/invoice_bulk" >
                            <Buttons
                                innertxt={"Invoices Bulk Upload"}
                                innerclr={"ff9ee8"}
                            />
                        </Link>
                    </div> */}
                </div></>}
            {(Modules && Modules.includes(6) || Modules && Modules.includes(0)) && <><div className='head_top_of_b_c_n_t mt-4'>
                <p>Credit Note</p>
            </div>
                <div className='row'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/All_credit_n" onClick={credit_note_tab}>
                            <Buttons
                                innertxt={"All Credit Note"}
                                innerclr={"7a5029"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Credit_note_p_a" onClick={credit_pending_tab}>
                            <Buttons
                                innertxt={"Credit Note Pending Approval"}
                                innerclr={"b3234b"}
                            />
                        </Link>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Credit_note_approved" onClick={app_credit_note_tab}>
                            <Buttons
                                innertxt={"Credit Note Approved"}
                                innerclr={"2a6d26"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Credit_note_E_L" onClick={app_credit_note_tab}>
                            <Buttons
                                innertxt={"Credit Note Email List"}
                                innerclr={"757474"}
                            />
                        </Link>
                    </div>
                </div></>}
            {(Modules && Modules.includes(7) || Modules && Modules.includes(0)) && <><div className='head_top_of_b_c_n_t mt-4'>
                <p>Proforma Invoices</p>
            </div>
                <div className='row'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to='/All_proforma_invoice' onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"All Proforma Invoices"}
                                innerclr={"7a5029"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to='/Performa_pending_approvel' onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Proforma  Pending Approval"}
                                innerclr={"b3234b"}
                            />
                        </Link>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to='/Performa_invoice_approved' onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Proforma Invoices Approved"}
                                innerclr={"2a6d26"}
                            />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to='/Performa_invoice_rejected' onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Proforma Invoices Rejected"}
                                innerclr={"757474"}
                            />
                        </Link>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to='/Performa_Email_list' onClick={handleClearAndClick}>
                            <Buttons
                                innertxt={"Proforma Email List"}
                                innerclr={"03a2d6"}
                            />
                        </Link>
                    </div>
                </div></>}
            {(Modules && Modules.includes(1) || Modules && Modules.includes(0)) && <>
                <div className='head_top_of_b_c_n_t mt-4'>
                    <p>Customer Master</p>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Customer_master" onClick={custom_master}>
                            <Buttons
                                innertxt={"Customer Master"}
                                innerclr={"a29617"}
                            />
                        </Link>
                    </div>
                </div></>}
            {Modules && Modules.includes(0) && <><div className='head_top_of_b_c_n_t mt-4'>
                <p>Report</p>
            </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Reports" onClick={() => {
                            setInvoiceDataMaster({ invoiceTypeIdentifier: '', startDate: null, endDate: null, InvoiceStatus: '1' });
                            setstartdate(null)
                            setenddate(null)
                        }}>
                            <Buttons
                                innertxt={"Report"}
                                innerclr={"7a5029"}
                            />
                        </Link>
                    </div>
                </div>
                <div className='head_top_of_b_c_n_t mt-4'>
                    <p>User Master</p>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/User_master" onClick={usermaster}>
                            <Buttons
                                innertxt={"User Master"}
                                innerclr={"a29617"}
                            />
                        </Link>
                    </div>
                </div></>}
            {(Modules && Modules.includes(2) || Modules && Modules.includes(3) || Modules && Modules.includes(4) || Modules && Modules.includes(5) || Modules && Modules.includes(0)) && <div className='head_top_of_b_c_n_t mt-4'>
                <p>Configuration</p>
            </div>}
            <div className='row mt-2'>
                {(Modules && Modules.includes(2) || Modules && Modules.includes(0)) && <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                    <Link to="/Supplier_config" onClick={handleAscendingClick}>
                        <Buttons
                            innertxt={"Supplier Configuration"}
                            innerclr={"d6a103"}
                        />
                    </Link>
                </div>}
                {(Modules && Modules.includes(3) || Modules && Modules.includes(0)) && <div className='col-lg-6 col-12  pe-1 btn_in_prp'>
                    <Link to="/Invoice_type_config" onClick={invoice_type_intial}>
                        <Buttons
                            innertxt={"Invoice Type"}
                            innerclr={"0aa28e"}
                        />
                    </Link>
                </div>}
            </div>
            <div className='row mt-2'>
                {(Modules && Modules.includes(5) || Modules && Modules.includes(0)) && <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                    <Link to="/Unit_measurement_main_table" onClick={unit_master_intitial}>
                        <Buttons
                            innertxt={"Units of Measurement"}
                            innerclr={"757474"}
                        />
                    </Link>
                </div>}
                {(Modules && Modules.includes(4) || Modules && Modules.includes(0)) && <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                    <Link to="/Item_master_main_table" onClick={item_master_intital}>
                        <Buttons
                            innertxt={"Item Master"}
                            innerclr={"812dad"}
                        />
                    </Link>
                </div>}
            </div>
            {Modules && Modules.includes(0) && (<><div className='row mt-2'>
                <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                    <Link to="/TCS">
                        <Buttons
                            innertxt={"TCS"}
                            innerclr={"b3234b"}
                        />
                    </Link>
                </div>
                <div className='col-lg-6 col-12  pe-1 btn_in_prp'>
                    <Link to="/State_code_config" onClick={state_code_config}>
                        <Buttons
                            innertxt={"State Code Configuration"}
                            innerclr={"a29617"}
                        />
                    </Link>
                </div>
            </div>

                <div className='row mt-2'>
                    <div className='col-lg-6 col-12 pe-1 btn_in_prp'>
                        <Link to="/Payment_Trems_config">
                            <Buttons
                                innertxt={"Payment Terms Configuration"}
                                innerclr={"7a5029"}
                            />
                        </Link>
                    </div>
                </div></>)}
        </div>
    )
}

export default Button_container