import React, { useContext, useEffect } from 'react';
import { E_invoice_context } from '../../../../E_invoice_context';

const Credit_Approved_table = ({ isApproved }) => {
    //console.log(isApproved);
    const { setapprovalcreditdata,getApprovalcreditViewData,approvalcreditPagination,CurrentUser, approvalcreditdata, handleapprovalcreditviewdata, AllCheckMark, setAllCheckMark, setCheckMark, CheckMark } = useContext(E_invoice_context);
    // console.log(approvalcreditdata);
    
    useEffect(() => {
        if (CurrentUser)
            getApprovalcreditViewData();
    }, [approvalcreditPagination])
    
    const handleCheckMark = (identifier) => {
        if (identifier === "ALL") {
            if (approvalcreditdata) {
                approvalcreditdata.forEach((data) => {
                    if (CheckMark.includes(data.invoiceIdentifier)) {
                        setCheckMark((prevCheckMark) =>
                            prevCheckMark.filter((id) => id === data.invoiceIdentifier)
                        );
                    }
                    else {
                        setCheckMark((prevCheckMark) => [...prevCheckMark, data.invoiceIdentifier]);
                    }
                });
            }
        } else {
            if (CheckMark.includes(identifier)) {
                setCheckMark((prevCheckMark) => prevCheckMark.filter((id) => id !== identifier));
            } else {
                setCheckMark((prevCheckMark) => [...prevCheckMark, identifier]);
            }
        }
    };

    return (
        <div>

            <div className='table_str'>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            {isApproved ? (
                                <th>
                                    <input type="checkbox" name="MailBox" id="All_box" checked={AllCheckMark} onChange={() => { setAllCheckMark(!AllCheckMark); handleCheckMark("ALL") }} />
                                    <label className="mx-2 mb-2 filter_checkbox_white" htmlFor="All_box"></label>
                                </th>
                            ) : null}
                            <th><span style={{ opacity: '0' }}>Hidden</span></th>
                            <th>Credit Note</th>
                            <th>Invoice Number</th>
                            <th>Invoice Date</th>
                            <th>Customer Name</th>
                            <th>Customer Type</th>
                            <th>Invoice Type</th>
                            <th>Supplier GST</th>
                            <th>Contract Number</th>
                            <th>PAN Number</th>
                            <th>Status</th>
                            {isApproved ? (null) :
                                <th>Mail Status</th>
                            }
                        </tr>
                    </thead>
                    {approvalcreditdata && approvalcreditdata.length > 0 ? (
                        <tbody>
                            {approvalcreditdata && approvalcreditdata.map((item, item_id) => {
                                // Parse the input date string
                                // const invoiceDate = new Date(item.invoiceDate);
                                // const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB', {
                                //     day: '2-digit',
                                //     month: 'short',
                                //     year: 'numeric',
                                // });
                                // //console.log(formattedInvoiceDate);
                                return (
                                    <tr key={item_id}>
                                        {isApproved && (
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={CheckMark.includes(item.invoiceIdentifier)}
                                                    name="MailBox2"
                                                    id={item.invoiceIdentifier}
                                                    onClick={() => handleCheckMark(item.invoiceIdentifier)}
                                                />
                                                <label className="mx-2 mt-2 filter_checkbox_white" htmlFor={item.invoiceIdentifier}></label>
                                            </td>
                                        )}
                                        <td><button className='c_m_btn' onClick={() => handleapprovalcreditviewdata(item.invoiceIdentifier)}>View</button></td>
                                        <td>{item.invoiceNoteSerialNumber}</td>
                                        <td>{item.invoiceSerialNumber}</td>
                                        <td>{item.invoiceDate}</td>
                                        <td>{item.customerName}</td>
                                        <td>{item.customerType === "1" ? 'CMS' : (item.customerType === "2" ? 'NON CMS' : '')}</td>
                                        <td>{item.invoiceType}</td>
                                        <td>{item.supplierGST}</td>
                                        <td>{item.contractNumber}</td>
                                        <td>{item.panNumber}</td>
                                        <td>{item.crStatus === "1" ? 'Pending' : (item.crStatus === "2" ? "Approved" : 'Rejected')}</td>
                                        {isApproved ? null :
                                            <td>{item.mailflag === true ? 'Send' : 'Pending'}</td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="9" style={{ fontSize: '15px', textAlign: 'center' }}>
                                    No records found.
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

export default Credit_Approved_table;
