import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'
import Credit_Approved_table from '../Credit_note_approved/credit_note_approved_table'
import Credit_Approved_View from '../Credit_note_approved/credit_note_approved_view'
import { useNavigate } from 'react-router-dom'

const Credit_note_approved = () => {
  const { sessionEndTime, approvalcreditPagination, invoicing, invoiceAccess, approvalcreditresponse, handleSendmailapprovalcredit, approvalcredithandlePageChange, approvalcreditpagenumbers, setapprovalcreditsearch,
    approvalcreditsearch, handleapprovalsearch, getApprovalcreditViewData, setapprovalcreditinvoicerecord, approvalcreditinvoicerecord,
    approvalcreditshow, setapprovalcreditshow, setAllCheckMark, usemaster_config, invoiceviewdropdown, decryptData, itemdropdown } = useContext(E_invoice_context);


  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_creditapproved').click();
    }
    setAllCheckMark(false)
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }

  useEffect(() => {
    getApprovalcreditViewData();
  }, [approvalcreditinvoicerecord, setapprovalcreditinvoicerecord]);

  var invoiceTypeDown = []

  if (invoicing && invoicing.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.makerFlag === true || rec.viewFlag === true)));
  }
  else {
    invoiceTypeDown = itemdropdown
  }
  const credit_invoice_app_search = (e) => {
    if (e.keyCode === 13) {
      handleapprovalsearch();
    }
  }
  const credit_approve_search_no = (e) => {
    setapprovalcreditsearch(e.target.value)
  }

  return (
    <div>
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
      <input type='button' hidden id='go_to_logintime_creditapproved' data-bs-toggle="modal" data-bs-target="#go_to_login_creditapproved" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_creditapproved" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className='h4_btm_bdr'>
        {
          approvalcreditshow && approvalcreditshow === 1 ? ('') : (<i onClick={() => setapprovalcreditshow(1)} className="fa-solid fa-circle-arrow-left"></i>)
        }
        <h4>Credit Note Approved</h4>
      </div>
      {
        approvalcreditshow === 1 &&
        <div className='total_outfit_fr_tab'>
          <div className='create_btn_top'>
            <div>
              <button className="grn_btn" onClick={handleSendmailapprovalcredit}>Send Mail</button>
            </div>
            <div className='invoice_em_top_head crdit_note'>
              <select value={approvalcreditinvoicerecord.invoiceTypeIdentifier} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, invoiceTypeIdentifier: e.target.value, currentPage: approvalcreditPagination.Current_page = 1 })}>
                <option selected value={""}>Invoice Type</option>
                {
                  invoiceTypeDown && invoiceTypeDown.map((item, item_id) => (
                    <option key={item_id} value={item.invoiceTypeConfigurationIdentifier}>{item.invoiceTypeName}</option>
                  ))
                }
              </select>
              <select value={approvalcreditinvoicerecord.supplierIdentifier} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setapprovalcreditinvoicerecord({ ...approvalcreditinvoicerecord, supplierIdentifier: e.target.value, currentPage: approvalcreditPagination.Current_page = 1 })}>
                <option selected value={""}>Supplier Name</option>
                {
                  invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                    <option key={uq} value={invoicedrop.supplierIdentifier}>{invoicedrop.supplierTradeName}</option>
                  ))
                }
              </select>
              <input className="search_inp_filed" value={approvalcreditsearch} onKeyDown={credit_invoice_app_search} type="text" name="" id="" placeholder="Search" onChange={credit_approve_search_no} />
              <i className="fa-solid fa-magnifying-glass search_icon" onClick={handleapprovalsearch}></i>
            </div>
          </div>
          <Credit_Approved_table
            setapprovalcreditshow={setapprovalcreditshow}
            isApproved={true}
          />
          <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <div>
              <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{approvalcreditresponse}</span></p>
            </div>
            <div>
              {approvalcreditresponse != 0 && <Pagination
                pageNumbers={approvalcreditpagenumbers}
                Paginations={approvalcreditPagination}
                handlePageChange={approvalcredithandlePageChange}
                LastPage={approvalcreditresponse && Math.ceil(approvalcreditresponse / 10)}
              />}
            </div>
          </div>
        </div>
      }

      {
        approvalcreditshow == 2 &&
        <div>
          <Credit_Approved_View />
        </div>
      }
    </div>
  )
}

export default Credit_note_approved