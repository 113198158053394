import React, { useContext, useEffect, useState } from 'react';
import { E_invoice_context } from '../E_invoice_context';
import RedirectLoader from './RedirectLoader'; // Assuming you have a RedirectLoader component

const SSO_CallBack = () => {
    const { GetLoginDetails } = useContext(E_invoice_context)
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        sessionStorage.clear();
    }, []);


    useEffect(() => {
        const url = new URL(window.location.href);
        const randValue = url.searchParams.get('code');
        const decodedRandValue = randValue ? decodeURIComponent(randValue) : null;
        // console.log('Rand value:', decodedRandValue);
        GetLoginDetails(decodedRandValue);
        setRedirecting(true);
      }, []);

    return (
        <div className='container frm_bg'>
            {redirecting && redirecting ? (
                <RedirectLoader />
            ) : (
                <div className='container m-5 p-5 frm_bg'>Loader...</div>
            )}
        </div>
    );
};

export default SSO_CallBack;