import React, { useContext } from 'react';
import { E_invoice_context } from '../../../../E_invoice_context';

const Main_table_invoice = ({ isApproved }) => {
    const { InvoiceRecords, MailCheckMark, setMailCheckMark, AllCheckMark, setAllCheckMark, handleViewInvoice, handleClickInvoice, handleInvoiceDownload } = useContext(E_invoice_context);

    const handleMailCheck = (identifier) => {
        if (identifier === "ALL") {
            if (InvoiceRecords) {
                InvoiceRecords.forEach((Inv) => {
                    if (MailCheckMark.includes(Inv.invoiceIdentifier)) {

                        setMailCheckMark((prevMailCheckMark) =>
                            prevMailCheckMark.filter((id) => id === Inv.invoiceIdentifier)
                        );
                    }
                    else {
                        setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, Inv.invoiceIdentifier]);
                    }
                });
            }
        } else {
            if (MailCheckMark.includes(identifier)) {
                setMailCheckMark((prevMailCheckMark) => prevMailCheckMark.filter((id) => id !== identifier));
            } else {
                setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, identifier]);
            }
        }
    };


    // console.log(MailCheckMark)


    return (
        <div>
            <div className='table_str'>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            {/* {isApproved ? ( */}
                                <th>
                                    <input type="checkbox" name="MailBox" id="All" checked={AllCheckMark} onChange={() => { setAllCheckMark(!AllCheckMark); handleMailCheck("ALL") }} />
                                    <label className="filter_checkbox_white" htmlFor="All"></label>
                                </th>
                            {/* ) : null} */}
                            <th style={{ width: '8%' }}></th>
                            <th onClick={() => { handleClickInvoice('invoiceNumber') }}>Invoice Number</th>
                            <th onClick={() => { handleClickInvoice('invoiceDate') }}>Invoice Date</th>
                            <th onClick={() => { handleClickInvoice('customerName') }}>Customer Name</th>
                            <th onClick={() => { handleClickInvoice('customerType') }}>Customer Type</th>
                            <th onClick={() => { handleClickInvoice('invoiceType') }}>Invoice Type</th>
                            <th onClick={() => { handleClickInvoice('supplierGST') }}>Supplier GST</th>
                            <th onClick={() => { handleClickInvoice('contractNumber') }}>Contract Number</th>
                            <th onClick={() => { handleClickInvoice('supplierGST') }}>PAN Number</th>
                            <th onClick={() => { handleClickInvoice('supplierGST') }}>State Code</th>
                            <th onClick={() => { handleClickInvoice('supplierGST') }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InvoiceRecords && InvoiceRecords.length ? (
                            InvoiceRecords.map((Inv, ind) => (
                                <tr key={ind}>
                                    {/* {isApproved && ( */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={MailCheckMark.includes(Inv.invoiceIdentifier)}
                                                name="MailBox2"
                                                id={Inv.invoiceIdentifier}
                                                onChange={() => handleMailCheck(Inv.invoiceIdentifier)}
                                            />
                                            <label className="filter_checkbox_white" htmlFor={Inv.invoiceIdentifier}></label>
                                        </td>
                                    {/* )} */}
                                    <td>
                                        {/* <button
                                            onClick={() => handleViewInvoice(Inv.invoiceIdentifier)}
                                            type="button"
                                            className="btn btn-primary grid_inner_btn btn_mar_left"
                                        >
                                            View
                                        </button> */}
                                        <i style={{fontSize: '15px'}} onClick={() => handleViewInvoice(Inv.invoiceIdentifier)} className='fa-solid fa-eye'></i>
                                        <i style={{ marginLeft: '10px', fontSize: '15px' }} onClick={() => handleInvoiceDownload(Inv.invoiceIdentifier, 0)} className="fa-solid fa-download"></i>
                                    </td>
                                    <td>{Inv.invoiceSerialNumber}</td>
                                    <td>{Inv.invoiceDate.toString()}</td>
                                    <td>{Inv.customerName}</td>
                                    <td>{Inv.customerType === "1" ? "NON-CMS" : "CMS"}</td>
                                    <td>{Inv.invoiceType}</td>
                                    <td>{Inv.supplierGST}</td>
                                    <td>{Inv.contractNumber}</td>
                                    <td>{Inv.panNumber}</td>
                                    <td>{Inv.billingState}</td>
                                    <td>{Inv.invstatus === "0" ? "Pending" : Inv.invstatus === "1" ? "Approved" : "Rejected"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='text-center' colSpan={12}>
                                    No Records Found...
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>




        </div>
    );
};

export default Main_table_invoice;
