import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'

const Credit_pending_table = () => {

    const { pendingcreditviewdata, handlependingcreditviewdata, getPendingCreditInvoiceData, CurrentUser, pendingcreditinvoicerecord } = useContext(E_invoice_context);

    useEffect(() => {
        if (CurrentUser)
            getPendingCreditInvoiceData();
    }, [pendingcreditinvoicerecord])

    return (
        <div>
            <div className='table_str'>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            <th><span style={{ opacity: '0' }}>Hidden</span></th>
                            <th>Credit Note</th>
                            <th>Invoice Number</th>
                            <th>Invoice Date</th>
                            <th>Customer Name</th>
                            <th>Customer Type</th>
                            <th>Invoice Type</th>
                            <th>Supplier GST</th>
                            <th>Contract Number</th>
                            <th>PAN Number</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    {
                        pendingcreditviewdata && pendingcreditviewdata.length > 0 ? (
                            <tbody>
                                {
                                    pendingcreditviewdata && pendingcreditviewdata.map((item, item_id) => (
                                        <tr key={item_id}>
                                            <td><button className='c_m_btn' onClick={() => handlependingcreditviewdata(item.invoiceIdentifier)}>View</button></td>
                                            <td>{item.invoiceNoteSerialNumber}</td>
                                            <td>{item.invoiceSerialNumber}</td>
                                            <td>{item.invoiceDate}</td>
                                            <td>{item.customerName}</td>
                                            <td>{item.customerType}</td>
                                            <td>{item.invoiceType}</td>
                                            <td>{item.supplierGST}</td>
                                            <td>{item.contractNumber}</td>
                                            <td>{item.panNumber}</td>
                                            <td>{item.crStatus === "1" ? 'Pending' : (item.crStatus === "2" ? "Approved" : 'Rejected')}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="9" style={{ fontSize: '15px', textAlign: 'center' }}>
                                        No records found.
                                    </td>
                                </tr>
                            </tbody>
                        )}
                </table>
            </div>
        </div>
    )
}


export default Credit_pending_table