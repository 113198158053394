
import "./Fontstyle.css"
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import Login_form from "./Login_form/Login_form";
import Unauthorized from "./Main_content/Tables_content/Unauthorized/Unauthorized";
import SSOPage from "./SSO_Login/SSOPage";
import SSO_CallBack from "./SSO_Login/SSO_CallBack";
function App() {
  return (
    <div className="container-fluid ps-0 pe-0">
      <input type="text" id='downloadempty' hidden data-bs-toggle="modal" data-bs-target="#downloademptymdl" />
      <div className="modal fade mdl_e_fade" id="downloademptymdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title">Alert</h5>
              <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mdl_bdy">
              Select an Invoice for Download
            </div>
            <div className="modal-footer">
              <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<SSOPage />} />
        <Route path="/login" element={<Login_form />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/callback" element={<SSO_CallBack />} />
      </Routes>
    </div>
  );
}

export default App;
