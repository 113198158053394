import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'
import Credit_pending_table from '../Credit_Note_pending.jsx/credit_note_pending_table'
import Credit_pending_view_data from '../Credit_Note_pending.jsx/credit_note_pending_view'
import { useNavigate } from 'react-router-dom'

const Credit_note_p_a = () => {
  const { sessionEndTime, pendingcreditinvoicerecord, invoicing, invoiceAccess, getPendingCreditInvoiceData, pendingcreditpagenumbers, itemdropdown,
    pendingcreditPagination, pendingcredithandlePageChange, pendingcreditresponse, handlependingcreditsearchdata,
    setpendingsetcreditinvoicerecord, pendingcreditshow, setpendingCreditShow, usemaster_config, invoiceviewdropdown,
    setinvoicedropdownvalue, itemresponse, setpendingcreditsearch, pendingcreditsearch, decryptData } = useContext(E_invoice_context)
  useEffect(() => {
    getPendingCreditInvoiceData();
  }, [pendingcreditinvoicerecord, setpendingsetcreditinvoicerecord]);


  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_credit_pending').click();
    }
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }

  var invoiceTypeDown = []

  if (invoicing && invoicing.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.makerFlag === true || rec.viewFlag === true)));
  }
  else {
    invoiceTypeDown = itemdropdown
  }
  const credit_pending_search = (e) => {
    if (e.keyCode === 13) {
      handlependingcreditsearchdata();
    }
  }
  const credit_pending_search_no = (e) => {
    setpendingcreditsearch(e.target.value)
  }

  return (
    <div>
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
      <input type='button' hidden id='go_to_logintime_credit_pending' data-bs-toggle="modal" data-bs-target="#go_to_login_credit_pending" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_credit_pending" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className='h4_btm_bdr'>
        {
          pendingcreditshow && pendingcreditshow === 1 ? ('') : (<i onClick={() => setpendingCreditShow(1)} className="fa-solid fa-circle-arrow-left"></i>)
        }
        <h4>Credit Note Pending</h4>
      </div>
      {
        pendingcreditshow === 1 &&
        <div className='total_outfit_fr_tab'>
          <div className='invoice_em_top_head crdit_note'>
            <select value={pendingcreditinvoicerecord.invoiceTypeIdentifier} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, invoiceTypeIdentifier: e.target.value, currentPage: pendingcreditinvoicerecord.currentPage = 1 })}>
              <option selected value={""}>Invoice Type</option>
              {
                invoiceTypeDown && invoiceTypeDown.map((item, item_id) => (
                  <option key={item_id} value={item.invoiceTypeConfigurationIdentifier}>{item.invoiceTypeName}</option>
                ))
              }
            </select>
            <select value={pendingcreditinvoicerecord.supplierIdentifier} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setpendingsetcreditinvoicerecord({ ...pendingcreditinvoicerecord, supplierIdentifier: e.target.value, currentPage: pendingcreditinvoicerecord.currentPage = 1 })}>
              <option selected value={""}>Supplier Name</option>
              {
                invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                  <option key={uq} value={invoicedrop.supplierIdentifier}>{invoicedrop.supplierTradeName}</option>
                ))
              }
            </select>
            <input value={pendingcreditsearch} onKeyDown={credit_pending_search} className="search_inp_filed" type="text" name="" id="" placeholder="Search" onChange={credit_pending_search_no} />
            <i className="fa-solid fa-magnifying-glass search_icon" onClick={handlependingcreditsearchdata}></i>
          </div>
          <Credit_pending_table
            setpendingCreditShow={setpendingCreditShow}
          />
          {/* <div className='mt-4'>
            {pendingcreditresponse != 0 && <Pagination
              pageNumbers={pendingcreditpagenumbers}
              Paginations={pendingcreditPagination}
              handlePageChange={pendingcredithandlePageChange}
              LastPage={pendingcreditresponse && Math.ceil(pendingcreditresponse / 10)}
            />}
          </div> */}
          <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <div>
              <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{pendingcreditresponse}</span></p>
            </div>
            <div>
              {pendingcreditresponse != 0 && <Pagination
                pageNumbers={pendingcreditpagenumbers}
                Paginations={pendingcreditPagination}
                handlePageChange={pendingcredithandlePageChange}
                LastPage={pendingcreditresponse && Math.ceil(pendingcreditresponse / 10)}
              />}
            </div>
          </div>
        </div>
      }
      {
        pendingcreditshow == 2 &&
        <Credit_pending_view_data />
      }
    </div>
  )
}

export default Credit_note_p_a