// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/Fonts/daimler-cs-regular_eot.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/Fonts/daimler-cs-regular_woff.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../src/Fonts/daimler-cs-regular_ttf.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../src/Fonts/daimler-cs-regular_svg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#DaimlerCS-Regular" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'DaimlerCS-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg');
    font-weight: normal;
    font-style: normal;
}
body{
    font-family: 'DaimlerCS-Regular', Arial, sans-serif !important;
    overflow-y: hidden;
    overflow-x: hidden;
}
a{
    text-decoration: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Fontstyle.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,4CAAmD;IACnD,mPAA0S;IAC1S,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,8DAA8D;IAC9D,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,gCAAgC;AACpC","sourcesContent":["@font-face {\n    font-family: 'DaimlerCS-Regular';\n    src: url('../src/Fonts/daimler-cs-regular_eot.eot');\n    src: url('../src/Fonts/daimler-cs-regular_eot.eot?#iefix') format('embedded-opentype'), url('../src/Fonts/daimler-cs-regular_woff.woff') format('woff'), url('../src/Fonts/daimler-cs-regular_ttf.ttf') format('truetype'), url('../src/Fonts/daimler-cs-regular_svg.svg#DaimlerCS-Regular') format('svg');\n    font-weight: normal;\n    font-style: normal;\n}\nbody{\n    font-family: 'DaimlerCS-Regular', Arial, sans-serif !important;\n    overflow-y: hidden;\n    overflow-x: hidden;\n}\na{\n    text-decoration: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
