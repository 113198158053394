import React, { useContext, useEffect, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'
import Non_CMS_Customer_Create from './Non_CMS_Customer_Create'

const Customer_master = ({ isHide }) => {
    const { SetShow, suplierstatecode, noncmssearch, handlenoncmssearchclick, create_customer_pending_approvel_cms, CreateViewCustomerData, setCreateViewCustomerData, NON_CMSCustomerPaginationNumbers, paginationnoncmscustom,
        handleNON_CMSCustomerPaginationNumber, ShipIdentifier, setShipIdentifier, BillIdentifier, setBillIdentifier, Getnoncmscustomerdata, ViseFlag, pendingcmserr, serpendingcmserr, setpaginationnoncmcscustom, EditViewFlag,
         setEditViewFlag, CreateViewCustomerDataCount, setnonCMS, setnoncmssearch, nonCMS, GetCustomer, Editnoncmstable } = useContext(E_invoice_context)


    const handleBack = () => {
        if (ViseFlag) {
            SetShow(4)
        }
        else
            SetShow(2)
        setCreateViewCustomerData([])
    }

    // useEffect(() => {
    //     setShipIdentifier(ShipIdentifier)
    //     setBillIdentifier(BillIdentifier)
    // }, [ShipIdentifier, BillIdentifier])

    // useEffect(() => {
    //     setBillIdentifier(CreateViewCustomerData && CreateViewCustomerData[0].tblGstapiinvoiceCustomerBillAddresses[0] && CreateViewCustomerData[0].tblGstapiinvoiceCustomerBillAddresses[0] === undefined
    //         && CreateViewCustomerData[0].tblGstapiinvoiceCustomerBillAddresses[0].invoiceCustomerBillAddressIdentifier)
    // }, [])


    // console.log()

    const GetNonCMSCustomers = (type, identifier) => {
        GetCustomer(type, identifier)
        if (ViseFlag) {
            SetShow(4)
        }
        else
            SetShow(2)
    }

    const EditCustomer = async (id) => {
        await Editnoncmstable(id)
        SetShow(5)
        serpendingcmserr({ ...pendingcmserr, ship_address: '3', bill_address: '3' })
    }

    const ViewCustomer = async (id) => {
        await Editnoncmstable(id)
        setEditViewFlag("1")
        serpendingcmserr({ ...pendingcmserr, ship_address: '3', bill_address: '3' })
    }

    const CreateCustomer = () => {
        if (isHide) {
            create_customer_pending_approvel_cms();
            setEditViewFlag("1")
            SetShow(5)
        }
        else {
            create_customer_pending_approvel_cms();
            SetShow(5)
        }
    }
    const noncmsentersearch = (e) => {
        if (e.keyCode === 13) {
            handlenoncmssearchclick();
        }
    }

    // useEffect(() => {
    //     GetCustomer(1, '')
    // }, [paginationnoncmscustom.Current_page])

    const billingpageset = (e) => {
        setnonCMS({ ...nonCMS, billingStatesCode: e.target.value, currentPage: 1 })
        setpaginationnoncmcscustom({ ...paginationnoncmscustom, Current_page: paginationnoncmscustom.Current_page = 1 });

    }
    const shippingpageset = (e) => {
        setnonCMS({ ...nonCMS, shippingStatesCode: e.target.value, currentPage: 1 })
        setpaginationnoncmcscustom({ ...paginationnoncmscustom, Current_page: paginationnoncmscustom.Current_page = 1 });

    }
    const reg_page_set = (e) => {
        setnonCMS({ ...nonCMS, regUnRegFlag: e.target.value, currentPage: 1 })
        setpaginationnoncmcscustom({ ...paginationnoncmscustom, Current_page: paginationnoncmscustom.Current_page = 1 });
    }
    const searchpageset = (e) => {
        setnoncmssearch(e.target.value)
        setpaginationnoncmcscustom({ ...paginationnoncmscustom, Current_page: paginationnoncmscustom.Current_page = 1 });
    }
    const collapse_get_radio = (iq) => {
        const one = CreateViewCustomerData && CreateViewCustomerData.filter((cre) => (cre.invoiceCustomerMasterIdentifier === iq))
        const tbl_bill = one[0].tblGstapiinvoiceCustomerBillAddresses[0]
        const tbl_ship = one[0].tblGstapiinvoiceCustomerShipAddresses[0]
        tbl_bill && setBillIdentifier(tbl_bill.invoiceCustomerBillAddressIdentifier)
        tbl_ship && setShipIdentifier(tbl_ship.invoiceCustomerShipAddressIdentifier)
    }

    // console.log(CreateViewCustomerData)
    return (


        (EditViewFlag === "0" ? (<div>
            <div className="create_btn_top">
                <div className='creat_c_m'>
                    {!isHide && <i onClick={handleBack} className="fa-solid fa-circle-arrow-left"></i>}
                    <button onClick={CreateCustomer} className="grn_btn">Create Customer</button>
                </div>
                <div className='create_right_aligh'>
                    <select value={nonCMS && nonCMS.billingStatesCode} className='view_tab_select' onChange={billingpageset} name='Billingstate' >
                        <option value="">Billing States</option>
                        {suplierstatecode && suplierstatecode.map((item) => (
                            <option key={item.userAutoID} value={item.stateCode}>
                                {item.stateName}
                            </option>
                        ))}
                    </select>
                    <select className='view_tab_select' value={nonCMS && nonCMS.shippingStatesCode} onChange={shippingpageset} name='shippingstate'>
                        <option value="">Shipping States</option>
                        {suplierstatecode && suplierstatecode.map((item) => (
                            <option key={item.userAutoID} value={item.stateCode}>
                                {item.stateName}
                            </option>
                        ))}
                    </select>
                    <select value={nonCMS && nonCMS.regUnRegFlag} className="select_inp_filed" onChange={reg_page_set} aria-label="Default select example">
                        <option selected value='0'>All</option>
                        <option value="1">Registerd</option>
                        <option value="2">Unregisterd</option>
                    </select>
                    <input value={noncmssearch} onKeyDown={noncmsentersearch} onChange={searchpageset} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
                    <i onClick={handlenoncmssearchclick} className="fa-solid fa-magnifying-glass search_icon"></i>
                </div>
            </div>
            <div className='table_c_m'>
                <table className='table_c__M'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Customer Trade Name</th>
                            <th>Customer Legal Name</th>
                            <th>Customer PAN Number</th>
                        </tr>
                    </thead>
                    <tbody id="accordionFlushExample">
                        {
                            CreateViewCustomerData && CreateViewCustomerData.map((cmsCustomer, noncmsiq) => (
                                <>

                                    <tr key={noncmsiq}>
                                        {isHide ?
                                            (<td className='c_m_btn_td'>
                                                <button onClick={() => ViewCustomer(cmsCustomer.invoiceCustomerMasterIdentifier)} className='c_m_btn'>View</button>
                                            </td>)
                                            :
                                            (<td className='c_m_btn_td'><button onClick={() => EditCustomer(cmsCustomer.invoiceCustomerMasterIdentifier)} className='c_m_btn'>Edit</button> <button disabled={(cmsCustomer.tblGstapiinvoiceCustomerBillAddresses && cmsCustomer.tblGstapiinvoiceCustomerShipAddresses && cmsCustomer.tblGstapiinvoiceCustomerBillAddresses.length === 0 && cmsCustomer.tblGstapiinvoiceCustomerShipAddresses.length === 0)} onClick={() => GetNonCMSCustomers(1, cmsCustomer.invoiceCustomerMasterIdentifier)} className='c_m_btn'>Select</button>
                                                {
                                                    (cmsCustomer.tblGstapiinvoiceCustomerBillAddresses || cmsCustomer.tblGstapiinvoiceCustomerShipAddresses) && (cmsCustomer.tblGstapiinvoiceCustomerBillAddresses.length || cmsCustomer.tblGstapiinvoiceCustomerShipAddresses.length) ? (
                                                        <i data-bs-toggle="collapse" onClick={() => collapse_get_radio(cmsCustomer.invoiceCustomerMasterIdentifier)} data-bs-target={`#${"id_" + cmsCustomer.invoiceCustomerMasterIdentifier}`} className="collapsed fa-solid fa-circle-plus fa_change"></i>
                                                    ) : ('')
                                                }
                                            </td>)
                                        }
                                        <td>{cmsCustomer.customerTradeName}</td>
                                        <td>{cmsCustomer.customerLegalName}</td>
                                        <td>{cmsCustomer.panNumber}</td>
                                    </tr>
                                    <tr className="collapse" data-bs-parent="#accordionFlushExample" id={"id_" + cmsCustomer.invoiceCustomerMasterIdentifier}>
                                        <td></td>
                                        <td >{cmsCustomer && cmsCustomer.tblGstapiinvoiceCustomerBillAddresses && cmsCustomer.tblGstapiinvoiceCustomerBillAddresses.length ? (
                                            <>
                                                {cmsCustomer.tblGstapiinvoiceCustomerBillAddresses.map((bill_adr, uq) => (
                                                    <div className='mb-3'>
                                                        <div className='bill_adddress ship'>
                                                            <div className='bill_addres_head'>
                                                                <input
                                                                    name={'bill' + noncmsiq}
                                                                    id={'bill_address_' + uq}
                                                                    type="radio"
                                                                    value={bill_adr.invoiceCustomerBillAddressIdentifier}
                                                                    onChange={() => setBillIdentifier(bill_adr.invoiceCustomerBillAddressIdentifier)}
                                                                    checked={(BillIdentifier === bill_adr.invoiceCustomerBillAddressIdentifier)}
                                                                    className=''
                                                                />
                                                                <label htmlFor={'bill_address_' + uq}>{`Bill To Address ${uq + 1}`}</label>
                                                            </div>
                                                            <div className='bill_address_body'>
                                                                <p className='b_a_b_gstin'>GSTIN:{bill_adr.customerGstin}</p>
                                                                <p className='other_list'>{`${bill_adr.customerBuildingName},`}{`${bill_adr.customerBuildingNumber},`}
                                                                    {`${bill_adr.customerDistrict},`}{`${bill_adr.customerPincode},`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : ('')}</td>
                                        <td style={{ marginLeft: '10px' }}>{cmsCustomer && cmsCustomer.tblGstapiinvoiceCustomerShipAddresses && cmsCustomer.tblGstapiinvoiceCustomerShipAddresses.length ? (
                                            <>
                                                {cmsCustomer.tblGstapiinvoiceCustomerShipAddresses.map((ship_adr, uq) => (
                                                    <div className='mb-3'>
                                                        <div className='bill_adddress ship'>
                                                            <div className='bill_addres_head'>
                                                                <input
                                                                    name={'ship' + noncmsiq}
                                                                    id={'ship_address_' + uq}
                                                                    type="radio"
                                                                    value={ship_adr.invoiceCustomerShipAddressIdentifier}
                                                                    onChange={(e) => setShipIdentifier(ship_adr.invoiceCustomerShipAddressIdentifier)}
                                                                    checked={(ShipIdentifier === ship_adr.invoiceCustomerShipAddressIdentifier)}
                                                                    className=''
                                                                />
                                                                <label htmlFor={'ship_address_' + uq}>{`ship To Address ${uq + 1}`}</label>
                                                            </div>
                                                            <div className='bill_address_body'>
                                                                <p className='b_a_b_gstin'>GSTIN:{ship_adr.shipGstin}</p>
                                                                <p className='other_list'>{`${ship_adr.shipBuildingName},`}{`${ship_adr.shipBuildingNumber},`}
                                                                    {`${ship_adr.shipDistrict},`}{`${ship_adr.shipPincode},`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </>
                                        ) : ''}</td>
                                        <td></td>
                                    </tr>

                                </>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div>
                <input type="text" id='alert_delete_customer' hidden data-bs-toggle="modal" data-bs-target="#alert_delete_customer_model" />

                <div className="modal fade mdl_e_fade" id="alert_delete_customer_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                        <div className="modal-content mdl_cont">
                            <div className="modal-header mdl_header">
                                <h5 className="modal-title mdl_title">Alert</h5>
                                <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body mdl_bdy">
                                Customer is already in use and cannot be deleted.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{CreateViewCustomerDataCount}</span></p>
                </div>
                <div>
                    <Pagination
                        pageNumbers={NON_CMSCustomerPaginationNumbers}
                        Paginations={paginationnoncmscustom}
                        handlePageChange={handleNON_CMSCustomerPaginationNumber}
                        LastPage={CreateViewCustomerDataCount && Math.ceil(CreateViewCustomerDataCount / 10)}
                    />
                </div>
            </div>
        </div>) : (<Non_CMS_Customer_Create isHide={isHide} setEditViewFlag={setEditViewFlag} del={1} />))



    )
}

export default Customer_master