import React, { useContext } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'


const Invoice_email_table = ({ Emailinvoicedata, Emailinvoiceresponse,
    invoiceEmailpageNumbers, EmailinvoicePaginations, invoicehEmailandlePageChange, handleEmailSortClick }) => {

    const { handleViewInvoice, setMailCheckMark, MailCheckMark, AllCheckMark, setAllCheckMark, handleInvoiceDownload } = useContext(E_invoice_context)

    const handleMailCheck = (identifier) => {
        if (identifier === "ALL") {
            if (Emailinvoicedata) {
                Emailinvoicedata.forEach((Inv) => {
                    if (MailCheckMark.includes(Inv.invoiceIdentifier)) {

                        setMailCheckMark((prevMailCheckMark) =>
                            prevMailCheckMark.filter((id) => id === Inv.invoiceIdentifier)
                        );
                    }
                    else {
                        setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, Inv.invoiceIdentifier]);
                    }
                });
            }
        } else {
            if (MailCheckMark.includes(identifier)) {
                setMailCheckMark((prevMailCheckMark) => prevMailCheckMark.filter((id) => id !== identifier));
            } else {
                setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, identifier]);
            }
        }
    };


    return (
        <div>
            <div className='table_str'>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" name="MailBox" id="All" checked={AllCheckMark} onChange={() => { setAllCheckMark(!AllCheckMark); handleMailCheck("ALL") }} />
                                <label className=" filter_checkbox_white" htmlFor="All"></label>
                            </th>
                            <th style={{ width: '5%' }}></th>
                            <th onClick={() => handleEmailSortClick('invoiceNumber')}>Invoice Number</th>
                            <th onClick={() => handleEmailSortClick('invoiceDate')}>Invoice Date</th>
                            <th onClick={() => handleEmailSortClick('customerName')}>Customer Name</th>
                            <th onClick={() => handleEmailSortClick('customerType')}>Customer Type</th>
                            <th onClick={() => handleEmailSortClick('invoiceType')}>Invoice Type</th>
                            <th onClick={() => handleEmailSortClick('supplierGST')}>Supplier GST</th>
                            <th onClick={() => handleEmailSortClick('contractNumber')}>Contract Number</th>
                            <th onClick={() => handleEmailSortClick('panNumber')}>PAN Number</th>
                            <th onClick={() => handleEmailSortClick('supplierGST')}>State Code</th>
                            <th onClick={() => handleEmailSortClick('invstatus')}>Status</th>
                            <th onClick={() => handleEmailSortClick('mailFlag')}>Mail Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Emailinvoicedata && Emailinvoicedata.map((invoice, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={MailCheckMark.includes(invoice.invoiceIdentifier)}
                                        name="MailBox2"
                                        id={invoice.invoiceIdentifier}
                                        onClick={() => handleMailCheck(invoice.invoiceIdentifier)}
                                    />
                                    <label className="filter_checkbox_white" htmlFor={invoice.invoiceIdentifier}></label>
                                </td>
                                <td>
                                    <i style={{ fontSize: '15px' }} onClick={() => handleViewInvoice(invoice.invoiceIdentifier)} className='fa-solid fa-eye'></i>
                                    <i style={{ marginLeft: '10px', fontSize: '15px' }} onClick={() => handleInvoiceDownload(invoice.invoiceIdentifier, 0)} className="fa-solid fa-download"></i>
                                </td>
                                <td>{invoice.invoiceSerialNumber}</td>
                                <td>{invoice.invoiceDate}</td>
                                <td>{invoice.customerName}</td>
                                <td>{invoice.customerType == '1' ? ('Non CMS') : ('CMS')}</td>
                                <td>{invoice.invoiceType}</td>
                                <td>{invoice.supplierGST}</td>
                                <td>{invoice.contractNumber}</td>
                                <td>{invoice.panNumber}</td>
                                <td>{invoice.billingState}</td>
                                <td>{invoice.invstatus == '0' ? ('Pending') : ('Approved')}</td>
                                <td>{invoice.mailFlag == 'False' ? ('Pending') : ('sent')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Emailinvoiceresponse}</span></p>
                </div>
                <div>
                    <Pagination
                        pageNumbers={invoiceEmailpageNumbers}
                        Paginations={EmailinvoicePaginations}
                        handlePageChange={invoicehEmailandlePageChange}
                        LastPage={Emailinvoiceresponse && Math.ceil(Emailinvoiceresponse / 10)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Invoice_email_table