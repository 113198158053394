import React, { useContext, useEffect } from 'react'
import Pagination from '../../Pagination'
import { E_invoice_context } from '../../../../E_invoice_context'

const Non_CMS_Customer_Create = ({ isHide, setEditViewFlag, del }) => {
    const { SetShow, createcustomernoncms, createnoncmsinput, NoncmsCreatedata, AllCheckMark, setAllCheckMark, pendingcmserr, Editboxaddressbill, Stateconfigdata, non_cms_Edit_show, shipadressboxedit, Editboxaddressship, billadressboxedit, billaddressdelete,
        suplierstatecode, serpendingcmserr, GetCustomer, setNoncmsbilladdress, Noncmsbilladdress, billaddressbox, handlechangebilladdress,
        Addaddressship, handlechangeshipaddress, Customernoncmsdelete, noncmsbilladdress, Noncmsshipaddress, GetItemData, Noncmsshipaddresssave, setnoncmsinvoicedata, noncmsinvoicedata, cmspagenumbers, noncmsinvoicepagination, noncmsinvoicedatacount,
        handlesavenoncmsedit, editidentifier, Addaddressbill, shipadressboxdelete, handleSendmail, Getnoncmscustomerdata, handlecmspagechange, MailCheckMark,
         setMailCheckMark,Getnoncmscustomerid,validatePAN } = useContext(E_invoice_context)


        useEffect(() => {
            Getnoncmscustomerdata();
            validatePAN(NoncmsCreatedata.customerPanNumber)
        }, [Getnoncmscustomerid])


    const noncmsback = () => {
        if (isHide) {
            setEditViewFlag("0")
        }
        else {
            SetShow(3)
        }
        GetItemData();
    }

    const handleMailCheck = (identifier) => {
        // console.log(identifier)
        if (identifier === "ALL") {

            if (noncmsinvoicedata) {
                noncmsinvoicedata.forEach((Inv) => {
                    if (MailCheckMark.includes(Inv.invoiceIdentifier)) {

                        setMailCheckMark((prevMailCheckMark) =>
                            prevMailCheckMark.filter((id) => id === Inv.invoiceIdentifier)
                        );
                    }
                    else {
                        setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, Inv.invoiceIdentifier]);
                    }
                });
            }
        } else {
            if (MailCheckMark.includes(identifier)) {
                setMailCheckMark((prevMailCheckMark) => prevMailCheckMark.filter((id) => id !== identifier));
            } else {
                setMailCheckMark((prevMailCheckMark) => [...prevMailCheckMark, identifier]);
            }
        }
    };

    const pendindcmscancelbtn = () => {
        serpendingcmserr({ ...pendingcmserr, Edit_noncms: '2' })
    }

    const deleteclick = () => {
        document.getElementById('Delete_customer').click();
    }
    const modeldeleteclick = () => {
        Customernoncmsdelete();
    }

    // console.log("billaddress", Editboxaddressbill)
    // console.log("shipaddress", Editboxaddressship)

    // console.log(cmspagenumbers)
    // console.log(noncmsinvoicepagination)
    // console.log(handlecmspagechange)
    // console.log(noncmsinvoicedatacount)
    return (
        <div>

            <input type="text" id='model_noncmserg' hidden data-bs-toggle="modal" data-bs-target="#model_noncms" />

            <div className="modal fade mdl_e_fade" id="model_noncms" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Customer has been saved successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='Email_null_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_mdl" />
            <div className="modal fade mdl_e_fade" id="Email_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Please select Customer Name
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='Email_Success_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_Success_mdl" />
            <div className="modal fade mdl_e_fade" id="Email_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Mail sent successfully.
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='Email_Fail_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_Success_mdl" />
            <div className="modal fade mdl_e_fade" id="Email_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something went wrong.
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type='button' hidden id='Delete_customer' data-bs-toggle="modal" data-bs-target="#deleteModal_customer" />
            <div className="modal fade mdl_e_fade" tabIndex="-1" id="deleteModal_customer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to delete this customer?
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => modeldeleteclick()} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='noncms_delete' hidden data-bs-toggle="modal" data-bs-target="#noncms_delete_model" />

            <div className="modal fade mdl_e_fade" id="noncms_delete_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Customer deleted successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='alert_delete_customer' hidden data-bs-toggle="modal" data-bs-target="#alert_delete_customer_model" />

            <div className="modal fade mdl_e_fade" id="alert_delete_customer_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Customer is already in use and cannot be deleted.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ border: '1px solid #c7c7c7' }}>
                <div className="create_btn_top A_c_c">
                    <div className='creat_c_m'>
                        <i onClick={noncmsback} className="fa-solid fa-circle-arrow-left"></i>
                        <p style={{ color: 'red', marginBottom: '0', fontSize: '14px' }}>Customer Trade and Legal Name</p>
                    </div>
                    <div className='create_right_aligh_a_c_s'>
                        {
                            pendingcmserr.Edit_noncms === '2' && (
                                <button onClick={deleteclick} className="grn_btn">Delete</button>
                            )
                        }
                    </div>
                </div>
                <div className='creat_adres_sec'>
                    <div className='row c_a_s_r'>
                        <div className='col-lg-4 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">Customer Trade Name</label>
                            <input disabled={pendingcmserr.Edit_noncms === '2'} onChange={createnoncmsinput} className="search_inp_filed in_tb" type="text" value={NoncmsCreatedata.customerTradeName} name="customerTradeName" placeholder=""></input>
                            {
                                pendingcmserr.noncms === false ? (
                                    <p style={{ fontSize: '12px', color: 'red', marginBottom: '0' }}>{NoncmsCreatedata.customerTradeName === "" || NoncmsCreatedata.customerTradeName.length > 100 ? ('Please Provide valid information ') : ('')}</p>
                                ) : ('')
                            }

                        </div>
                        <div className='col-lg-4 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">Customer Legal Name</label>
                            <input disabled={pendingcmserr.Edit_noncms === '2'} onChange={createnoncmsinput} className="search_inp_filed in_tb" type="text" value={NoncmsCreatedata.customerLegalName} name="customerLegalName" placeholder=""></input>
                            {
                                pendingcmserr.noncms === false ? (
                                    <p style={{ fontSize: '12px', color: 'red', marginBottom: '0' }}>{NoncmsCreatedata.customerLegalName === "" || NoncmsCreatedata.customerLegalName.length > 100 ? ('Please Provide valid information') : ('')}</p>
                                ) : ('')
                            }
                        </div>
                        <div className='col-lg-4 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">Customer PAN Number</label>
                            <input disabled={pendingcmserr.Edit_noncms === '2'} onChange={createnoncmsinput} className="search_inp_filed in_tb" type="text" value={NoncmsCreatedata.customerPanNumber} name="customerPanNumber" placeholder=""></input>
                            {
                                pendingcmserr.noncms === false ? (
                                    <p style={{ fontSize: '12px', color: 'red', marginBottom: '0' }}>{NoncmsCreatedata.customerPanNumber === "" || pendingcmserr.create_pan_err === false ? ('Please Provide valid PAN Number') : ('')}</p>
                                ) : ('')
                            }

                        </div>
                    </div>
                    <div className='A_c_c_btn'>
                        {
                            pendingcmserr.Edit_noncms === '1' && (
                                <button onClick={createcustomernoncms} className="grn_btn">Create Customer and Add Address</button>
                            )
                        }
                        {
                            pendingcmserr.Edit_noncms === '2' && (
                                <button onClick={non_cms_Edit_show} className="grn_btn">Edit</button>
                            )
                        }
                        {
                            pendingcmserr.Edit_noncms === '3' && (
                                <div style={{ display: 'flex', gap: '10px' }}>  <button onClick={handlesavenoncmsedit} className="grn_btn">Save</button>  <button onClick={() => { pendindcmscancelbtn(); Getnoncmscustomerdata(); }} className="diable_btn_edit">Cancel</button></div>
                            )
                        }
                    </div>
                    {
                        pendingcmserr.Edit_noncms === '2' && (
                            <div>
                                <div className='hide_sed_acc'>
                                    <p>Bill to Address</p>
                                    <button className='c_m_btn' onClick={Addaddressbill} style={{ fontSize: '13px' }}>+ Add Address</button>
                                </div>
                                {
                                    pendingcmserr.bill_address === '2' && (
                                        <div className='mt-3'>
                                            <div className='bill_to_add_sec_align'>
                                                <div className='row bill_add_rw'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer GSTIN</label>
                                                        <input onChange={handlechangebilladdress} value={Noncmsbilladdress.customerGstin} className="search_inp_filed in_tb" type="text" name="customerGstin" placeholder=""></input>
                                                        {/* {
                                                            pendingcmserr.nomcmsbilladdresserr === "2" && (
                                                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Noncmsbilladdress.customerGstin === "" || pendingcmserr.create_sup_gst_err === false ? ('Plesase Provide Valid GSTIN Number') : ('')}</p>
                                                            )
                                                        } */}

                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Building Number</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerBuildingNumber} type="text" name="customerBuildingNumber" placeholder=""></input>

                                                        {/* {
                                                            pendingcmserr.nomcmsbilladdresserr === "2" && (
                                                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Noncmsbilladdress.customerBuildingNumber === "" || noncmsbilladdress.customerBuildingNumber.length>100 ? ('Plesase Provide Valid Information and Cannot exceed 100 characters') : ('')}</p>
                                                            )
                                                        } */}
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Building Name</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerBuildingName} type="text" name="customerBuildingName" placeholder=""></input>
                                                        {/* {
                                                            pendingcmserr.nomcmsbilladdresserr === "2" && (
                                                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Noncmsbilladdress.customerBuildingName === "" || noncmsbilladdress.customerBuildingName.length>100? ('Plesase Provide Valid Information and Cannot exceed 100 characters') : ('')}</p>
                                                            )
                                                        } */}


                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Location</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerLocation} type="text" name="customerLocation" placeholder=""></input>
                                                        {/* {
                                                            pendingcmserr.nomcmsbilladdresserr === "2" && (
                                                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Noncmsbilladdress.customerLocation === "" || noncmsbilladdress.customerLocation.length>150 ? ('Plesase Provide Valid Information and Cannot exceed 150 characters') : ('')}</p>
                                                            )
                                                        } */}

                                                    </div>
                                                </div>
                                                <div className='row bill_add_rw tw'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer District</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerDistrict} type="text" name="customerDistrict" placeholder=""></input>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Pincode</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerPincode} type="text" name="customerPincode" placeholder=""></input>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer State Code</label>
                                                        <select onChange={handlechangebilladdress} style={{ width: '100%' }} id='a_c_i_sel' name='customerStateCode' value={Noncmsbilladdress.customerStateCode} className="select_inp_filed" aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            {suplierstatecode.map((item) => (
                                                                <option key={item.stateName} value={item.stateCode}>
                                                                    {item.stateName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Billing POS</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.billingPos} type="text" name="billingPos" placeholder=""></input>
                                                        {Noncmsbilladdress && Noncmsbilladdress.billingPos && Noncmsbilladdress.billingPos.length > 2 ? <p style={{ marginBottom: '0', color: 'red', fontSize: '12px' }}>Billing POS Must Be in Two Characters</p> : ''}
                                                    </div>
                                                </div>
                                                <div className='row bill_add_rw tr'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Phone</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerPhone} type="text" name="customerPhone" placeholder=""></input>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Customer Email</label>
                                                        <input onChange={handlechangebilladdress} className="search_inp_filed in_tb" value={Noncmsbilladdress.customerEmail} type="text" name="customerEmail" placeholder=""></input>
                                                    </div>
                                                </div>
                                                {
                                                    editidentifier.copy_flag_edit === true &&
                                                    <div className='copy_s_addre'>
                                                        <input onChange={() => setNoncmsbilladdress(prev => ({ ...prev, copyFlag: !(prev.copyFlag) }))} type="checkbox" checked={Noncmsbilladdress.copyFlag } id='copy_addres' name="copyFlag" placeholder=""></input>
                                                        <label className='filter_checkbox_white' htmlFor="copy_addres">Copy Shiping Address</label>
                                                    </div>
                                                }

                                                <div className='Bill_save_can_btn'>
                                                    <button onClick={noncmsbilladdress} className="grn_btn">Save</button>
                                                    {
                                                        pendingcmserr.savecancel === '1' &&
                                                        <button onClick={() => { serpendingcmserr({ ...pendingcmserr, bill_address: '1' }); Getnoncmscustomerdata() }} className='btn_delete'>Cancel</button>
                                                    }
                                                    {
                                                        pendingcmserr.savecancel === '2' &&
                                                        <button onClick={() => { serpendingcmserr({ ...pendingcmserr, bill_address: '3' });  }} className='btn_delete'>Cancel</button>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    )
                                }
                                {
                                    pendingcmserr.bill_address === '3' &&
                                    <div>

                                        {
                                            Editboxaddressbill && Editboxaddressbill.map((editboxadress, edituq) => (
                                                <>
                                                    <div className='edit_box_invoice'>
                                                        <div className='edit_bx_head'>
                                                            <div><p>Bill To Address {edituq + 1}</p></div>
                                                            <div style={{ display: 'flex', gap: '10px' }}><button onClick={() => billadressboxedit(editboxadress.invoiceCustomerBillAddressIdentifier)} className='grn_btn editbtn'>Edit</button>
                                                                <button onClick={() => billaddressdelete(editboxadress.invoiceCustomerBillAddressIdentifier)} className='btn_delete editbtn'>Delete</button></div>
                                                        </div>
                                                        <div className='edit_box_cnt'>
                                                            <p>{editboxadress.customerBuildingNumber}</p>
                                                            <p>{editboxadress.customerBuildingName}</p>
                                                            <p>{editboxadress.customerLocation}</p>
                                                            <p>{editboxadress.customerEmail}</p>
                                                        </div>
                                                        <div className='edit_bx_footer'>
                                                            <div>
                                                                <i data-bs-toggle="collapse" data-bs-target={`#collpase_${edituq + 1}`} className="fa-solid fa-phone"></i> <span>{editboxadress.customerPhone}</span>
                                                            </div>
                                                            <i data-bs-toggle="collapse" data-bs-target={`#collpase_${edituq + 1}`} className="fa-solid fa-ellipsis"></i>
                                                        </div>
                                                        <div className="collapse" id={`collpase_${edituq + 1}`}>
                                                            <table className="box_btm_table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="left_td">Customer District </td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{editboxadress.customerDistrict}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Customer Pincode</td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{editboxadress.customerPhone} </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Customer State Code</td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{editboxadress.customerStateCode} </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Billing POS </td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{editboxadress.customerStateCode} </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }


                                    </div>
                                }
                                <div className='hide_sed_acc'>
                                    <p>Ship To Address</p>
                                    <button onClick={Addaddressship} className='c_m_btn' style={{ fontSize: '13px' }}>+ Add Address</button>
                                </div>
                                {
                                    pendingcmserr.ship_address === '2' && (
                                        <div className='mt-3'>
                                            <div className='bill_to_add_sec_align'>
                                                <div className='row bill_add_rw'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> GSTIN</label>
                                                        <input value={Noncmsshipaddress.shipGstin} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipGstin" placeholder=""></input>
                                                        {/* {
                                                            pendingcmserr.nomcmsshipaddresserr === "2" &&
                                                            (< p style={{ marginBottom: '0', color: 'red', fontSize: '12px' }}>{Noncmsshipaddress.shipGstin === '' || pendingcmserr.shipgstin_err === false ? ('Please Provide Valid Field GSTIN') : ('')}</p>)
                                                        } */}

                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> Building Number</label>
                                                        <input value={Noncmsshipaddress.shipBuildingNumber} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipBuildingNumber" placeholder=""></input>

                                                        {/* {
                                                            pendingcmserr.nomcmsshipaddresserr === "2" &&
                                                            (<p style={{ marginBottom: '0', color: 'red', fontSize: '12px' }}>{Noncmsshipaddress.shipBuildingNumber === '' ? ('Please Provide Valid Field') : ('')}</p>)
                                                        } */}

                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> Building Name</label>
                                                        <input value={Noncmsshipaddress.shipBuildingName} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipBuildingName" placeholder=""></input>

                                                        {/* {
                                                            pendingcmserr.nomcmsshipaddresserr === "2" &&
                                                            (<p style={{ marginBottom: '0', color: 'red', fontSize: '12px' }}>{Noncmsshipaddress.shipBuildingName === '' ? ('Please Provide Valid Field') : ('')}</p>)
                                                        } */}

                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> Location</label>
                                                        <input value={Noncmsshipaddress.shipLocation} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipLocation" placeholder=""></input>
                                                        {/* {
                                                            pendingcmserr.nomcmsshipaddresserr === "2" &&
                                                            <p style={{ marginBottom: '0', color: 'red', fontSize: '12px' }}>{Noncmsshipaddress.shipLocation === '' ? ('Please Provide Valid Field') : ('')}</p>
                                                        } */}

                                                    </div>
                                                </div>
                                                <div className='row bill_add_rw tw'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> District</label>
                                                        <input value={Noncmsshipaddress.shipDistrict} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipDistrict" placeholder=""></input>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> Pincode</label>
                                                        <input value={Noncmsshipaddress.shipPincode} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipPincode" placeholder=""></input>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> State Code</label>
                                                        <select value={Noncmsshipaddress.shipStateCode} style={{ width: '100%' }} name='shipStateCode' id='a_c_i_sel' onChange={handlechangeshipaddress} className="select_inp_filed" aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            {suplierstatecode.map((item) => (   //Stateconfigdata
                                                                <option key={item.stateName} value={item.stateCode}>
                                                                    {item.stateName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan">Phone</label>
                                                        <input value={Noncmsshipaddress.shipPhone} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipPhone" placeholder=""></input>
                                                    </div>
                                                </div>
                                                <div className='row bill_add_rw tr'>
                                                    <div className='col-lg-3 col-12'>
                                                        <label className="label_w_s" htmlFor="a_c_i_pan"> Email</label>
                                                        <input value={Noncmsshipaddress.shipEmail} onChange={handlechangeshipaddress} className="search_inp_filed in_tb" type="text" name="shipEmail" placeholder=""></input>
                                                    </div>
                                                </div>
                                                <div className='Bill_save_can_btn'>
                                                    <button onClick={Noncmsshipaddresssave} className="grn_btn">Save</button>
                                                    {
                                                        pendingcmserr.savecancel === "1" &&
                                                        <button onClick={() => { serpendingcmserr({ ...pendingcmserr, ship_address: '1' }); Getnoncmscustomerdata() }} className='btn_delete'>Cancel</button>
                                                    }
                                                    {
                                                        pendingcmserr.savecancel === "2" &&
                                                        <button onClick={() => { serpendingcmserr({ ...pendingcmserr, ship_address: '3' }); }} className='btn_delete'>Cancel</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    pendingcmserr.ship_address === '3' &&
                                    <div>
                                        {
                                            Editboxaddressship && Editboxaddressship.map((shipaddrss, shipuq) => (
                                                <>
                                                    <div className='edit_box_invoice'>
                                                        <div className='edit_bx_head'>
                                                            <div><p>Ship To Address {shipuq + 1}</p></div>
                                                            <div style={{ display: 'flex', gap: '10px' }}><button onClick={() => shipadressboxedit(shipaddrss.invoiceCustomerShipAddressIdentifier)} className='grn_btn editbtn'>Edit</button>
                                                                <button className='btn_delete editbtn' onClick={() => shipadressboxdelete(shipaddrss.invoiceCustomerShipAddressIdentifier)}>Delete</button></div>
                                                        </div>
                                                        <div className='edit_box_cnt'>
                                                            <p>{shipaddrss.shipBuildingNumber}</p>
                                                            <p>{shipaddrss.shipBuildingName}</p>
                                                            <p>{shipaddrss.shipLocation}</p>
                                                            <p>{shipaddrss.shipEmail}</p>
                                                        </div>
                                                        <div className='edit_bx_footer'>
                                                            <div>
                                                                <i data-bs-toggle="collapse" data-bs-target="#collapse_ed_2" className="fa-solid fa-phone"></i> <span>{shipaddrss.shipPhone}</span>
                                                            </div>
                                                            <i data-bs-toggle="collapse" data-bs-target="#collapse_ed_2" className="fa-solid fa-ellipsis"></i>
                                                        </div>
                                                        <div className="collapse" id="collapse_ed_2">
                                                            <table className="box_btm_table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="left_td">Customer District </td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{shipaddrss.shipDistrict}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Customer Pincode</td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{shipaddrss.shipPhone} </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Customer State Code</td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{shipaddrss.shipStateCode} </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="left_td">Billing POS </td>
                                                                        <td className="right_td">
                                                                            <p className="tr_btm_right_name">{shipaddrss.shipStateCode} </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }

                                    </div>
                                }

                                <div className='btm_approved_head'>
                                    <h6>Invoice</h6>
                                    <button className='c_m_btn' onClick={handleSendmail}>Send Email</button>
                                </div>
                                <div className='btm_approved_table'>
                                    <table className='table_c__M'>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" name="MailBox" id="All" checked={AllCheckMark} onChange={() => { setAllCheckMark(!AllCheckMark); handleMailCheck("ALL") }} />

                                                    <label className="mx-2 mb-2 filter_checkbox_white" htmlFor="All"></label>
                                                </th>
                                                {/* <th><span style={{ opacity: '0' }}>invoice</span></th> */}
                                                <th>Invoice Number</th>
                                                <th>Customer Name</th>
                                                <th>Customer Type</th>
                                                <th>Billing State</th>
                                                <th>Invoice Type</th>
                                                <th>Supplier GST</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {noncmsinvoicedata && noncmsinvoicedata.length ? (
                                                noncmsinvoicedata.map((noncmsitem, noncmsid) => (
                                                    <tr key={noncmsid}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={MailCheckMark.includes(noncmsitem.invoiceIdentifier)}
                                                                name="MailBox2"
                                                                id={noncmsitem.invoiceIdentifier}
                                                                onClick={() => handleMailCheck(noncmsitem.invoiceIdentifier)}
                                                            />
                                                            <label className="mx-2 mt-2 filter_checkbox_white" htmlFor={noncmsitem.invoiceIdentifier}></label>
                                                        </td>
                                                        <td>{noncmsitem.invoiceSerialNumber}</td>
                                                        <td>{noncmsitem.customerName}</td>
                                                        <td>{noncmsitem.customerType === "1" ? "NON-CMS" : "CMS"}</td>
                                                        <td>{noncmsitem.billingState}</td>
                                                        <td>{noncmsitem.invoiceType}</td>
                                                        <td>{noncmsitem.supplierGST}</td>
                                                        <td>{noncmsitem.invstatus === "0" ? "Pending" : noncmsitem.invstatus === "1" ? "Approved" : "Rejected"}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className='text-center' colSpan={8}>
                                                        No Invoice Created...
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <div>
                                        <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{noncmsinvoicedatacount && noncmsinvoicedatacount}</span></p>
                                    </div>
                                    <div>
                                        <Pagination
                                            pageNumbers={cmspagenumbers}
                                            Paginations={noncmsinvoicepagination}
                                            handlePageChange={handlecmspagechange}
                                            LastPage={noncmsinvoicedatacount && Math.ceil(noncmsinvoicedatacount / 10)}
                                        />
                                    </div>
                                </div>
                            </div>

                        )
                    }
                    <button onClick={noncmsback} className="grn_btn A_cc_btn mb-3">Go Back</button>


                    {/* {
                        Createcs && Createcs === 1 ? (
                            <div className='A_c_c_btn'>
                                <button onClick={() => Setcreatecs(2)} className="grn_btn">Create Customer and Add Address</button>
                                <button onClick={() => SetShow(3)} className="grn_btn A_cc_btn">Go Back</button>
                            </div>
                        ) : (
                            <>
                                <button className="grn_btn A_c_edit">Edit</button>
                                <div className='bill_to_add_sec'>
                                    <div className='hide_sed_acc'>
                                        <p>Bill to Address</p>
                                        <button onClick={() => SetBill(2)} className='c_m_btn' style={{ fontSize: '13px' }}>+ Add Address</button>
                                    </div>
                                    {
                                        Bill && Bill === 2 &&
                                        <Bill_address_table />
                                    }
                                    <All_invoice_edit_box boxhead={'Bill To Address 1'} />

                                </div>
                                <div className='bill_to_add_sec'>
                                    <div className='hide_sed_acc'>
                                        <p>Ship To Address</p>
                                        <button onClick={() => SetShip(2)} className='c_m_btn' style={{ fontSize: '13px' }}>+ Add Address</button>
                                    </div>
                                    {
                                        Ship && Ship === 2 &&
                                        <Ship_address_table />
                                    }
                                    <All_invoice_edit_box
                                        boxhead={'Ship To Address 1'}
                                    />
                                </div>
                                <button onClick={() => SetShow(3)} className="grn_btn A_cc_btn dw">Go Back</button>
                            </>
                        )

                    } */}
                </div>



            </div>

        </div >
    )
}

export default Non_CMS_Customer_Create